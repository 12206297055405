import React from "react";
import { Container } from "react-bootstrap";
import WebHeader from "../common/WebHeader";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  window.scrollTo(0, 0);
  return (
    <div className="">
      <div>
        <WebHeader />
      </div>

      <Container className="my-4">
        <h1 className="mt-5 fw-bold color_orange heebo">Privacy Policy</h1>
        <h2 className="mt-5 light_black fw-bold">1. Introduction</h2>

        <p className="fs_16">
          At Bituconnect, safeguarding your privacy and ensuring the security of
          your personal information is of paramount importance to us. This
          Privacy Policy is designed to inform you about the practices we employ
          to collect, use, share, and protect your data when you engage with our
          platform. By accessing and using our website and services, you
          acknowledge and agree to the practices outlined in this policy. This
          document aims to provide a clear understanding of how we handle your
          information and the steps we take to uphold your privacy rights.
        </p>

        <h2 className=" light_black pt-5 fw-bold">
          2. Types of Data Collected
        </h2>
        <p className="fs_16 mt-3">
          <strong>Personal Information: </strong>We collect personal information
          that you voluntarily provide to us through various forms on our
          platform. This includes basic details such as your name, email
          address, phone number, and any other identifying information you
          choose to share with us. This data is essential for us to deliver the
          services you request and to communicate effectively with you.
        </p>
        <p className="fs_16">
          <strong>Usage Data: </strong> Our systems automatically gather
          information regarding your interactions with our platform. This
          encompasses details such as your IP address, the type of browser you
          use, the operating system of your device, and your browsing behavior
          on our site. This data is crucial for analyzing how our platform is
          used, enhancing user experience, and identifying areas for
          improvement.
        </p>
        <p className="fs_16">
          <strong>Financial Information: </strong> In cases where you provide
          financial details for transaction purposes, we collect this data to
          facilitate the processing of transactions. However, it is important to
          note that Bituconnect does not store or process financial information
          directly. Payment processing is handled exclusively between buyers and
          sellers, ensuring that your financial data remains secure and is
          managed according to industry standards.
        </p>
        {/* <h2 className="mt-5 light_black fw-bold">Data Security</h2>
        <p className=" fs_17">
          Your data is securely stored and processed to ensure the highest
          levels of privacy. We utilize industry-standard encryption and
          security measures to protect your personal information from
          unauthorized access.
        </p> */}

        <h2 className="mt-5 light_black fw-bold">
          4. Purpose of Data Collection
        </h2>
        <p className=" fs_17">
          The data we collect serves several key purposes:
        </p>
        <ul>
          <li style={{ listStyleType: "initial", marginTop: "20px" }}>
            <strong>Facilitate Platform Functionality: </strong> We use your
            data to ensure the smooth operation of our platform and to provide
            you with the services you request. This includes processing your
            transactions, managing your account, and addressing any issues that
            may arise.
          </li>
          <li style={{ listStyleType: "initial", marginTop: "20px" }}>
            <strong>Marketing: </strong> We may use your data to communicate
            with you about our products, services, and promotions. This allows
            us to keep you informed about updates, offers, and other relevant
            information. You have the option to opt-out of marketing
            communications at any time by following the unsubscribe instructions
            provided in our emails or by adjusting your preferences on our
            platform.
          </li>
          <li style={{ listStyleType: "initial", marginTop: "20px" }}>
            <strong>Customer Support: </strong> To provide effective support, we
            use your data to assist with any inquiries or issues related to your
            use of our platform. This includes responding to your questions,
            resolving problems, and ensuring that your experience with our
            services is satisfactory.
          </li>
        </ul>
        <h2 className="mt-5 light_black fw-bold">
          5. Data Sharing with Third Parties
        </h2>
        <p className=" fs_17">
          We may disclose your data to third parties in specific circumstances:
        </p>
        <ul>
          <li style={{ listStyleType: "initial", marginTop: "20px" }}>
            <strong>Vendors: </strong> We share data with vendors to facilitate
            transactions between buyers and sellers on our platform. This
            includes sharing information necessary for order processing and
            transaction management.
          </li>
          <li style={{ listStyleType: "initial", marginTop: "20px" }}>
            <strong>Service Providers: </strong> We may collaborate with
            third-party service providers, such as payment processors and
            analytics companies, who assist us in operating and enhancing our
            platform. These providers are bound by confidentiality agreements
            and are only permitted to use your data for the purposes outlined in
            this policy.
          </li>
          <li style={{ listStyleType: "initial", marginTop: "20px" }}>
            <strong>Legal Requirements: </strong> In certain situations, we may
            disclose your data if required by law or to protect our legal
            rights. This includes responding to legal requests, complying with
            regulatory requirements, and defending against legal claims.
          </li>
        </ul>
        <h2 className="mt-5 light_black fw-bold">6. Data Protection</h2>
        <p className=" fs_17">
          We are committed to implementing robust security measures to protect
          your data:
        </p>
        <ul>
          <li style={{ listStyleType: "initial", marginTop: "20px" }}>
            <strong>Encryption: </strong> We utilize encryption technology to
            secure data transmitted between our platform and your device. This
            ensures that your information is protected from unauthorized access
            during transmission.
          </li>
          <li style={{ listStyleType: "initial", marginTop: "20px" }}>
            <strong>Secure Servers: </strong> Our data is stored on secure
            servers with advanced security protocols. This includes using
            firewalls, access controls, and other measures to safeguard your
            information from unauthorized access and breaches.
          </li>
        </ul>
        <p className=" fs_17">
          Despite our commitment to data protection, it is important to
          recognize that no security measure is entirely infallible. We
          continuously review and enhance our security practices to maintain a
          high level of protection for your data.
        </p>

        <h2 className="mt-5 light_black fw-bold">7. User Rights</h2>
        <p className=" fs_17">
          You have the following rights regarding your personal data:
        </p>
        <ul>
          <li style={{ listStyleType: "initial", marginTop: "20px" }}>
            <strong>Access: </strong> You are entitled to request access to the
            personal data we hold about you. This allows you to review the
            information we have collected and verify its accuracy.
          </li>
          <li style={{ listStyleType: "initial", marginTop: "20px" }}>
            <strong>Correction: </strong> If you identify any inaccuracies or
            incomplete information in your personal data, you can request that
            we correct it. We aim to ensure that all data we maintain is
            accurate and up-to-date.
          </li>
          <li style={{ listStyleType: "initial", marginTop: "20px" }}>
            <strong>Deletion: </strong> You may request the deletion of your
            personal data, subject to certain legal limitations. Please note
            that there may be circumstances where we are legally required to
            retain certain data.
          </li>
          <li style={{ listStyleType: "initial", marginTop: "20px" }}>
            <strong>Consent Management: </strong> You have the right to manage
            your consent preferences for data processing at any time. You can
            update your preferences or withdraw consent by contacting us using
            the information provided below.
          </li>
        </ul>
        <p className=" fs_17">
          To exercise these rights, please reach out to us through the contact
          details provided at the end of this policy.
        </p>
        <h2 className="mt-5 light_black fw-bold">
          8. Cookies and Tracking Technologies
        </h2>
        <p className=" fs_17">
          <strong>Types of Cookies:</strong> We utilize various types of cookies
          on our platform:
        </p>
        <ul>
          <li style={{ listStyleType: "initial", marginTop: "20px" }}>
            <strong>Essential Cookies: </strong> These are necessary for the
            basic functionality of the platform, enabling core features such as
            user authentication and session management.
          </li>
          <li style={{ listStyleType: "initial", marginTop: "20px" }}>
            <strong>Analytics Cookies: </strong> These cookies help us track
            usage patterns and gather insights about how our platform is used.
            This information aids in improving platform performance and user
            experience.
          </li>
          <li style={{ listStyleType: "initial", marginTop: "20px" }}>
            <strong>Tracking Technologies: </strong> We employ tracking
            technologies, such as pixels and web beacons, for personalized
            advertising and content customization.
          </li>
        </ul>
        <p className=" fs_17">
          <strong>Purpose of Cookies:</strong> Cookies and tracking technologies
          enhance our ability to deliver a better user experience by improving
          platform performance, offering personalized content, and gathering
          valuable insights.
        </p>
        <p className=" fs_17">
          <strong>Cookie Management:</strong> You have the ability to manage or
          disable cookies through your browser settings. Additionally, we
          provide a cookie consent management tool to assist you in managing
          your preferences.
        </p>
        <p className=" fs_17">
          <strong>Third-Party Cookies: </strong> Our platform may include
          third-party services that utilize their own cookies for purposes such
          as analytics and advertising. We do not control these cookies, and
          their use is subject to the third parties' privacy policies.
        </p>
        <h2 className="mt-5 light_black fw-bold">9. Children's Privacy</h2>
        <p className=" fs_17">
          Our platform is not intended for individuals under the age of 18. We
          do not knowingly collect or solicit personal data from minors. If you
          believe that we have collected personal data from a minor, please
          contact us, and we will take appropriate steps to remove the data.
        </p>
        <h2 className="mt-5 light_black fw-bold">
          10. Data Breach Notification
        </h2>
        <p className=" fs_17">
          In the event of a data breach, we will notify affected users as
          required by applicable laws and regulations. We will also take prompt
          and appropriate measures to mitigate any potential harm resulting from
          the breach.
        </p>
        <h2 className="mt-5 light_black fw-bold">
          11. International Data Transfers
        </h2>
        <p className=" fs_17">
          If your data is transferred to or processed in countries outside the
          United Arab Emirates, we will ensure that it is protected according to
          the standards outlined in this Privacy Policy. We implement
          appropriate safeguards to ensure that your data remains secure during
          international transfers.
        </p>
        <h2 className="mt-5 light_black fw-bold">
          12. Changes to the Privacy Policy
        </h2>
        <p className=" fs_17">
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or legal requirements. Any updates will be posted on
          our website, and the revised policy will take effect immediately upon
          posting. We encourage you to review this policy periodically to stay
          informed about how we protect your information.
        </p>
        <h2 className="mt-5 light_black fw-bold">13. Contact Information</h2>
        <p className=" fs_17">
          For any questions or concerns regarding this Privacy Policy or your
          personal data, please contact us at:
        </p>
        <ul>
          <li style={{ listStyleType: "initial", marginTop: "20px" }}>
            <a
              href="mailto:support@bituconnect.com
"
            >
              <strong>Support Email:</strong> support@bituconnect.com
            </a>
          </li>
        </ul>
      </Container>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
