import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import Footer from "./components/common/Footer";
import { Route, Routes } from "react-router-dom";
import TermsOfService from "./components/common/TermsOfService";
import PrivacyPolicy from "./components/common/PrivacyPolicy";
import MarineTraffic from "./components/common/MarineTraffic";
import WebHeader from "./components/common/WebHeader";
// import AboutUs from "./components/common/AboutUs";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/marine-traffic" element={<MarineTraffic />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        {/* <Route path="/about-us" element={<AboutUs />} /> */}
      </Routes>
    </div>
  );
}

export default App;
