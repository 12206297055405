import React from "react";
import text_image from "../../assets/png/text_image.svg";
import Accordion from "react-bootstrap/Accordion";
import { Container } from "react-bootstrap";

const OurProducts = () => {
  return (
    <div className="py-5" style={{ backgroundColor: "#f8f8f8" }}>
      <Container className=" text-center">
        <img src={text_image} alt="text_image" />
        <h4 className="fs_47 fw-bold manrope_FF mt-2">
          Do you have
          <span className=" color_orange"> Questions?</span>
        </h4>
        {/* <p className="fs_16 manrope_FF color_gray mt-4">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed eiusmod
          <br></br>
          tempor incididunt ut labore et dolore magna aliqua.
        </p> */}
        <Accordion
          defaultActiveKey="0"
          className="faq_width mt-5 mx-auto manrope_FF text-start"
        >
          <Accordion.Item className="mt-4" show eventKey="0">
            <Accordion.Header className="">
              <h5 className="mb-0 fs_16">
                {" "}
                01. What is the BituConnect platform?
              </h5>
            </Accordion.Header>
            <Accordion.Body>
              BituConnect is an online platform owned by BituTrading FZ-LLC that
              facilitates connections between buyers and vendors for the
              procurement of bitumen. It streamlines the purchasing process by
              allowing buyers to request quotes and vendors to submit offers,
              manage documents, and track orders efficiently.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-4" eventKey="1">
            <Accordion.Header>
              <h5 className="mb-0 fs_16">02. Who are our vendors?</h5>
            </Accordion.Header>
            <Accordion.Body>
              Our vendors are trusted suppliers of bitumen from around the
              world. They offer a variety of bitumen products in different
              packing options, such as drums, big bags, bitumen containers, and
              bulk shipments, ensuring that buyers have access to high-quality
              products from reliable sources.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-4" eventKey="2">
            <Accordion.Header>
              <h5 className="mb-0 fs_16">03. How can I be a vendor?</h5>
            </Accordion.Header>
            <Accordion.Body>
              To become a vendor on BituConnect, you need to sign up by visiting
              <a
                className=" color_orange text-decoration-underline"
                href="mailTo:BituConnect.com"
              >
                {" "}
                &nbsp;BituConnect.com&nbsp;
              </a>{" "}
              and filling out the vendor registration form. During the
              registration process, you will need to provide information about
              your business, products, and services.
              <p className=" mt-2">
                Once your application is submitted, our team will review it. As
                part of our verification process, you will be asked to complete
                a <strong> Know Your Customer (KYC)</strong> procedure to
                confirm your business credentials. After successfully passing
                this review, you will be notified and granted access to list
                your products and start receiving orders.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-4" eventKey="3">
            <Accordion.Header>
              <h5 className="mb-0 fs_16">04. How do we vet our vendors?</h5>
            </Accordion.Header>
            <Accordion.Body>
              BituConnect vets all vendors through a rigorous process to ensure
              they meet our standards. This includes verifying their business
              credentials, evaluating their previous performance in the market,
              and assessing their capacity to deliver products as promised. We
              aim to ensure that only reliable and professional vendors are part
              of the platform.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-4" eventKey="4">
            <Accordion.Header>
              <h5 className="mb-0 fs_16">05. How does the platform work?</h5>
            </Accordion.Header>
            <Accordion.Body>
              The BituConnect platform operates as a secure marketplace where
              buyers and vendors can connect. Buyers can create orders by
              specifying product details, quantity, and delivery preferences.
              Vendors then submit quotes, and buyers have the option to accept,
              request a price revision, or decline the quote.
              <p className="mt-2">
                Once an order is accepted, both parties can manage all
                documentation through the platform, including uploading
                Commercial Invoices and tracking shipments via the 'Track My
                Cargo' feature.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-4" eventKey="5">
            <Accordion.Header>
              <h5 className="mb-0 fs_16">06. How do I get support?</h5>
            </Accordion.Header>
            <Accordion.Body>
              If you have any issues or need assistance while using the
              BituConnect platform, our support team is here to help. You can
              reach out by sending an email to{" "}
              <strong> support@bituconnect.com </strong> or by generating a
              support ticket through the platform. Our team will respond as
              quickly as possible to assist you with any concerns.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </div>
  );
};

export default OurProducts;
