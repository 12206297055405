import React, { useState } from "react";
import "./common.css";

const CookieConsent = () => {
  const [show, setShow] = useState(!localStorage.getItem("cookieConsent"));

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setShow(false);
  };

  if (!show) return null;

  return (
    <div className="cookie-consent d-md-flex text-start  align-items-start align-items-md-center px-md-5 ">
      <p className="ps-md-4 ps-2 mb-0">
        We use cookies to ensure you get the best experience on our website.{" "}
        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
          Learn more
        </a>
      </p>
      <div className="pe-4 d-flex ps-md-4 ps-2 ps-md-0 mt-4 mt-md-0">
        {" "}
        <button
          className="accept-btn fw-bold px-4 rounded-5"
          onClick={handleAccept}
        >
          Got it
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
