import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import about_image from "../../assets/png/about_img.png";
import about_icon from "../../assets/svg/about_icon.svg";
import about_img from "../../assets/png/aboutUs1_img.jpeg";
import WebHeader from "../common/WebHeader";

const Bitutrading = () => {
  const [activeTab, setActiveTab] = useState("history");
  return (
    <div className=" min-vh-100  d-flex flex-column">
      <div>
        <WebHeader />
      </div>
      <Container className="py-5">
        <div className="row">
          <div className="col-lg-5">
            <img className="w-100 rounded-2" src={about_img} alt="about_img" />
          </div>
          <div className="col-lg-7">
            <div className="ps-lg-4 mt-4 mt-lg-0">
              <h1 className="mt-3 fw-semibold color_orange heebo">ABOUT US</h1>
              <p className="fs_16 mt-4">
                BituConnect is a cutting-edge platform created by professionals
                from the bitumen industry who have a deep understanding of the
                market’s needs. Operated by Bitutrading FZ-LLC, BituConnect was
                designed to streamline the bitumen procurement process, making
                it easier for buyers and vendors to connect and transact
                seamlessly.
              </p>
              <p className="fs_16 mt-4">
                As we move into a digital and tech-driven era, simplicity is at
                the heart of everything we do. Our platform offers an intuitive,
                user-friendly experience that simplifies the complexities of the
                bitumen supply chain.
              </p>
            </div>
          </div>
        </div>
        <p className="fs_16 mt-4">
          With features like real-time cargo tracking, secure document
          management, and efficient vendor-buyer communication, BituConnect
          ensures transparency and efficiency at every stage.
        </p>
        <p className="fs_16 mt-4">
          Our team’s extensive industry expertise ensures that BituConnect is
          not just a tool, but a comprehensive solution designed to save you
          time and optimize your procurement operations.
        </p>
      </Container>
    </div>
  );
};

export default Bitutrading;
