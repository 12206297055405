import React from "react";
import { Container } from "react-bootstrap";

const VideoSection = () => {
  return (
    <div className="video-section pb-5">
      <Container>
        <video className="w-100 card" controls poster="/video_img.png">
          <source className="w-100" src={`/demo2.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Container>
    </div>
  );
};

export default VideoSection;
