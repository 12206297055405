import React from "react";
import AboutHero from "../aboutpage/AboutHero";

const MarineTraffic = () => {
  return (
    <div>
      <AboutHero />
    </div>
  );
};

export default MarineTraffic;
