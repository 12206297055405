import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap"; // Import necessary components from React Bootstrap
import logo from "../../assets/svg/Logo.svg";
import facebook_icon from "../../assets/svg/facebook_icon.svg";
import youtube_icon from "../../assets/svg/youtube_icon.svg";
import in_icon from "../../assets/svg/in_icon.svg";
import x_icon from "../../assets/svg/x_icon.svg";
import { Link } from "react-router-dom";

const WebHeader = ({ displaynone, dlgflex, dlgnone }) => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };
  useEffect(() => {
    if (sidebarVisible) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [sidebarVisible]);
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClick = (id) => (event) => {
    event.preventDefault();
    scrollToSection(id);
  };
  return (
    <div className="bg-white shadow py-4">
      <Container>
        <Row className="align-items-center justify-content-between">
          <Col xs="auto">
            <Link to="/">
              <img width={200} className="mb-2" src={logo} alt="logo" />
            </Link>
          </Col>
          <Col xs="auto" className="d-flex align-items-center ">
            <ul className="ps-0 gap-4 d-none d-lg-flex mb-0 d-flex pe-4 align-items-center manrope_FF">
              {/* <Link to="/">
                <li>
                  <a className="fs_15  color_gray" href="#">
                    Home
                  </a>
                </li>
              </Link> */}
              <Link to="/about">
                <li>
                  <a className="fs_15 fw-semibold color_gray" href="#">
                    About Us
                  </a>
                </li>
              </Link>
              <li>
                <a className="fs_15 fw-semibold color_gray" href="/#ourMission">
                  Our Mission
                </a>
              </li>
              <Link to="/marine-traffic">
                <li>
                  <a className="fs_15 fw-semibold color_gray" href="#">
                    Marine Traffic
                  </a>
                </li>
              </Link>
            </ul>
            <div className={` d-none d-xl-flex align-items-center gap-3`}>
              {/* <img src={facebook_icon} alt="Facebook" />
              <img src={youtube_icon} alt="YouTube" /> */}
              <a
                href="https://www.linkedin.com/showcase/bituconnect/"
                target="_blanck"
              >
                <img src={in_icon} alt="LinkedIn" />
              </a>
              {/* <img src={x_icon} alt="X" /> */}
            </div>
            <div
              className={`navmanu d-lg-none position-relative custom_zindex d-block burger  ${
                sidebarVisible ? "active" : ""
              }`}
              onClick={toggleSidebar}
            >
              <div></div>
              <div className=""></div>
              <div></div>
            </div>

            <a target="_blanck" href="https://app.bituconnect.com/">
              <Button className="px-4 ms-3 d-lg-block d-none rounded-5 border-0 manrope_FF text-white fw-semibold py-2 bg_theme">
                Sign in
              </Button>
            </a>
            <a target="_blanck" href="https://app.bituconnect.com/sign-up">
              <Button className="px-4 ms-3 d-lg-block d-none rounded-5 border-1 border bg-white manrope_FF text-black border-black fw-semibold py-2 ">
                Sign Up &nbsp; &rarr;
              </Button>
            </a>
          </Col>
          {sidebarVisible && (
            <div
              className={`sidebar d-flex flex-column justify-content-center align-items-center  ${
                sidebarVisible ? "show " : "hide"
              }`}
            >
              <div className="d-lg-none d-flex  align-items-center gap-5 text-center">
                <ul className="ps-0 gap-4 flex-column mb-0 d-flex pe-4 align-items-center manrope_FF">
                  {/* <Link to="/">
                <li>
                  <a className="fs_15  color_gray" href="#">
                    Home
                  </a>
                </li>
              </Link> */}
                  {/* <Link to="/about"> */}
                  {/* <li>
                    <a className="fs_15 fw-semibold text-white" href="#">
                      About Us
                    </a>
                  </li> */}
                  {/* </Link> */}

                  <Link to="/marine-traffic">
                    <li>
                      <a className="fs_15 fw-semibold text-white" href="#">
                        Marine Traffic
                      </a>
                    </li>
                  </Link>
                  <li>
                    <a
                      className="fs_15 fw-semibold text-white"
                      href="/#ourMission"
                    >
                      Our Mission
                    </a>
                  </li>

                  <a target="_blanck" href="https://app.bituconnect.com/">
                    <Button className="px-4  rounded-5 border-0 manrope_FF text-white fw-semibold py-2 bg_theme">
                      Sign in <span>&rarr;</span>
                    </Button>
                  </a>
                </ul>
              </div>
            </div>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default WebHeader;
