import React from "react";
import Hero from "../components/homepage/Hero";
import "../components/homepage/home.css";
import HomeAboutUsComponent from "../components/homepage/HomeAboutUsComponent";
import OurProducts from "../components/homepage/OurProducts";
import Footer from "../components/common/Footer";
import TopHeroSection from "../components/homepage/TopHeroSection";
import FeaturesSection from "../components/homepage/FeaturesSection";
import VideoSection from "../components/homepage/VideoSection";
import CookieConsent from "../components/common/CookieConsent";

const HomePage = () => {
  return (
    <div>
      <TopHeroSection />
      <VideoSection />
      <FeaturesSection />
      {/* <Hero /> */}
      <HomeAboutUsComponent />
      <OurProducts />
      <Footer />
      <CookieConsent />
    </div>
  );
};

export default HomePage;
