import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import about_image from "../../assets/png/about_image.svg";
import "../../components/homepage/home.css";
import globe_gif from "../../assets/Globe.gif";

const HomeAboutUsComponent = () => {
  const [activeTab, setActiveTab] = useState("history");

  return (
    <div id="ourMission" className="py-5 mb-md-5">
      <Container>
        <Row className="justify-content-between align-items-center">
          <Col md={6}>
            <h2 className="fw-bold color_orange">Our Mission</h2>
            <p>
              Our mission is to create a reliable, transparent, and efficient
              marketplace for bitumen trading. We strive to enhance global
              connectivity between buyers and vendors by offering a platform
              that promotes clarity, convenience, and trust.
            </p>
            <div className="mt-4">
              <h4 className="fw-bold color_orange">For Buyers</h4>
              <h5 className=" fw-semibold">Effortless Purchasing of Bitumen</h5>
              <p>
                With BituConnect, purchasing bitumen is straightforward and
                efficient. Our platform streamlines the process from request to
                delivery, ensuring that buyers can secure the best deals on the
                market.
              </p>
            </div>
            <div className="mt-4">
              <h4 className="fw-bold color_orange">For Vendors</h4>
              <h5 className=" fw-semibold">
                Grow Your Business with Bituconnect
              </h5>
              <p>
                As a vendor on BituConnect, you have direct access to a global
                market of buyers looking for quality bitumen. The platform helps
                you to streamline communications, manage documents, and close
                deals quickly.
              </p>
            </div>
          </Col>
          <Col md={6} className="d-flex  justify-content-center">
            <img className="custom_w" src={globe_gif} alt="" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomeAboutUsComponent;
